import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.1166 18.0167C14.3833 18.2333 13.5166 18.3334 12.5 18.3334H7.49997C6.4833 18.3334 5.61663 18.2333 4.8833 18.0167C5.06663 15.85 7.29163 14.1417 9.99997 14.1417C12.7083 14.1417 14.9333 15.85 15.1166 18.0167Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.5 1.66669H7.49996C3.33329 1.66669 1.66663 3.33335 1.66663 7.50002V12.5C1.66663 15.65 2.61663 17.375 4.88329 18.0167C5.06663 15.85 7.29163 14.1417 9.99996 14.1417C12.7083 14.1417 14.9333 15.85 15.1166 18.0167C17.3833 17.375 18.3333 15.65 18.3333 12.5V7.50002C18.3333 3.33335 16.6666 1.66669 12.5 1.66669ZM9.99996 11.8083C8.34996 11.8083 7.01662 10.4667 7.01662 8.8167C7.01662 7.1667 8.34996 5.83335 9.99996 5.83335C11.65 5.83335 12.9833 7.1667 12.9833 8.8167C12.9833 10.4667 11.65 11.8083 9.99996 11.8083Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.9834 8.81666C12.9834 10.4667 11.6501 11.8083 10.0001 11.8083C8.35006 11.8083 7.01672 10.4667 7.01672 8.81666C7.01672 7.16666 8.35006 5.83331 10.0001 5.83331C11.6501 5.83331 12.9834 7.16666 12.9834 8.81666Z"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default SvgComponent;
