import React from "react";
import TopNavLogin from "../../../components/Nav/TopNavLogin";
import LoginAdmin from "../../../components/Sections/LoginAdmin";

const AdminLogin = () => {
  return (
    <>
      <TopNavLogin />
      <LoginAdmin />
    </>
  );
};

export default AdminLogin;
